// @import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
:root {
  /* Default primary color */
  //**************** Primary Color for sidebar,navbar,card ***********//
  //background color,text color,filter for image
  // '{"pbg":"#0f488f","pcolor":"#ffffff","sbg":"#0f488f","scolor":"#ffffff","accentColor":"#d5e7ff","textColor":"#022857","bColor":"#022857","pfilter":"brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(16deg) brightness(107%) contrast(102%)","sfilter":"brightness(0) saturate(100%) invert(98%) sepia(22%) saturate(0%) hue-rotate(230deg) brightness(111%) contrast(100%)","afilter":"brightness(0) saturate(100%) invert(88%) sepia(7%) saturate(1115%) hue-rotate(186deg) brightness(101%) contrast(100%)","tfilter":"brightness(0) saturate(100%) invert(12%) sepia(75%) saturate(1651%) hue-rotate(196deg) brightness(95%) contrast(105%)"}',
  --primary-bg: #0f488f;
  --primary-text: #022857;
  --primary-filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
    saturate(7499%) hue-rotate(174deg) brightness(100%) contrast(102%);
  //**************** Secondary Color for button ***********//
  //background color,text color,filter for image
  --secondary-bg: #0f488f;
  --secondary-text: #022857;
  --secondary-filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
    saturate(7499%) hue-rotate(174deg) brightness(100%) contrast(102%);
  //**************** Text Color for all text ***********//
  //text color,filter for image
  --text-color: #022857;
  --text-light: #e1f1f5;
  --text-filter: brightness(0) saturate(100%) invert(18%) sepia(25%)
    saturate(959%) hue-rotate(168deg) brightness(88%) contrast(82%);
  //**************** Accent Color for all tabs,table bg,modal bg ***********//
  --accent-color: #d5e7ff;
  --accent-filter: brightness(0) saturate(100%) invert(40%) sepia(8%)
    saturate(2999%) hue-rotate(148deg) brightness(93%) contrast(92%);
  //**************** Note if you want a light color of any color do this***********//
  --backdrop-color: #edf7f9;
  --heading-color: #022857;
  // --backdrop-color: rgb(240, 224, 224);
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

// $primary-color: #3b76ef;
// $primary-color: #2a7086;
$primary-color: var(--secondary-bg);

@mixin flex-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#root {
  width: 100vw;
  height: 100vh;
}

body {
  scrollbar-width: thin !important;
  scrollbar-color: #c4c4c4 #f6f6f6 !important;
  // scrollbar-gutter: stable both-edges;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0.2rem !important;
  height: 0.2rem;
  border-radius: 10px;
}

::-webkit-scrollbar:hover {
  box-shadow: 1px 1px 2px #b0afac inset;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c4c4c4 !important;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

::-webkit-scrollbar-thumb:hover {
  background-color: #5e5e5e !important ;
}

//overwriting antd styles
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

//diffrent buttons

.primary-button {
  background-color: var(--secondary-bg);
  color: var(--secondary-text);
  margin-left: 30px;
  width: fit-content;
  outline: none;
  padding: 5px 15px;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: none;
  &:hover {
    background-color: var(--secondary-bg);
    color: var(--secondary-text);
  }
}
.ant-transfer-list-body {
  border-radius: 15px;
}
.secondary-button {
  width: 100px;
  color: var(--secondary-bg);
  margin-left: 30px;
  width: fit-content;
  outline: none;
  padding: 5px 15px;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: none;
  &:hover {
    background-color: $primary-color;
    color: var(--secondary-text);
  }
}

//for delete-modal

.delete-modal {
  .ant-modal-content,
  .ant-modal-header {
    border-radius: 6px;
  }
  .ant-modal-title {
    text-align: center;
    color: var(--text-color);
    font-size: 20px;
  }
  .ant-modal-body {
    text-align: center;
    color: var(--text-color);
  }
  .ant-modal-footer {
    text-align: center;
    border-top: none;
    padding-top: 0;
    .ant-btn {
      margin: 0 20px;
    }
  }
}

//for antd drop down

.ant-dropdown {
  .ant-dropdown-menu {
    border: solid 0.5px #828282 !important;
    border-radius: 4px;
    padding: 0;
  }
  .ant-dropdown-menu-item-divider {
    margin: 0;
  }
  .ant-dropdown-menu-title-content {
    padding: 4px 2px !important;
  }
  .ant-menu-title-content {
    color: rgba(51, 51, 51, 0.86) !important;
  }
  // .ant-dropdown-menu-item:hover {
  //   background-color: rgba(47, 128, 237, 0.14);
  // }
}

//icon filter

.icon {
  color: var(--text-filter);
  &:hover {
    color: var(--text-filter);
  }
}

.icon-filter {
  &:hover {
    cursor: pointer;
    // filter: invert(39%) sepia(92%) saturate(2158%) hue-rotate(200deg)
    //   brightness(98%) contrast(89%);
  }
}

//tooltip

.ant-tooltip-inner {
  text-transform: capitalize;
}

//search input

.search-input {
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border: none;
  height: 35px;
  margin-left: 10px;
}

.centered-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-checkbox-wrapper:after {
  display: none !important;
}

//message styles

.ant-message {
  .success-msg {
    color: #fff;
    .ant-message-notice-content {
      background-color: #27ae60;
      padding: 10px 30px;
      .ant-message-custom-content {
        display: flex;
        justify-content: center;
        span {
          margin-left: 20px;
        }
      }
    }
  }
  .error-msg {
    color: #fff;
    .ant-message-notice-content {
      background-color: #fe4545;
      padding: 10px 30px;
      .ant-message-custom-content {
        display: flex;
        justify-content: center;
        span {
          margin-left: 20px;
        }
      }
    }
  }
}

//filter popover for using with inside buttons

.filter-popover {
  .ant-popover-inner-content {
    border-radius: 4px;
    border: solid 0.5px #828282;
    padding: 0;
  }
}

.ant-pagination {
  .ant-pagination-item {
    border-radius: 50%;
  }
  .ant-pagination-item-active {
    background-color: var(--secondary-bg);
    border: none;
    a {
      color: var(--text-color);
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    button {
      border: none;
    }
  }
}

.popup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.popup {
  text-align: center;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  max-width: 80%;
}

@media (max-width: 767px) {
  .popup {
    max-width: 100%;
  }
}

.Follow-btn-feed {
  font-weight: 500;
  color: var(--text-color);
  background: transparent;
  border: 1px solid var(--text-color);
  padding: 1px;
  padding-left: 6px;
  padding-right: 5px;
  border-radius: 50px;
  font-size: 12px;
  line-height: 17px;
  cursor: pointer;
  font-family: "Lato";
}
