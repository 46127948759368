// .Email-templates-component{

.email-temp-cont {
  height: 100%;

  .right {
    width: 270px !important;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    max-height: 20% !important;
    height: 50%;
    width: 0.3rem !important;
    border-radius: 0px 64px 10px 40px;
    height: 0.1rem;
    background: #9bb1bb4d;
    /* border-radius: 10px  */
  }
  ::-webkit-scrollbar-track {
    max-height: 20% !important;
    height: 20% !important;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #9bb1bb !important;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    // color: #fff;
    border-color: #2a7086;
    background: #2a7086;
  }

  .ant-form-item-explain {
    font-size: 9px !important;
  }

  .ant-form-item-explain {
    font-size: 9px !important;
    color: #ff4d4f !important;
  }

  .email-editor-cont {
    iframe {
      min-width: 959px !important;
    }
  }
  #editor-24 {
    iframe {
      min-width: 959px !important;
    }
  }

  //preview box
  .preview-box {
    display: flex;
    height: 100%;

    .email-temp-categories-cont {
      padding: 16px;
      height: calc(100% - 65px);
      box-sizing: border-box;
      width: 25%;
      background-color: #ffffff;
      margin: 22px 19px 3px 3px;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      h3 {
        font-size: 18px;
        font-weight: 500;
      }
      ul {
        margin-top: "7px";
        list-style: none;
        display: flex;
        size: 18px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
      }
      .email-templates-categories {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 10px;
        color: #2a7086;
        border-radius: 5px;
        margin: 5px;
      }
      .email-templates-categories:hover {
        cursor: pointer;
        background-color: #9bb1bb30;
      }
    }
  }

  .preview-template-component {
    width: calc(100% - 193px);
    display: flex;
    align-items: self-end;
    flex-direction: column;
    Button {
      margin-right: 47px;
      width: 10%;
      border-radius: 10px;
      border: none;
      font-size: 11px;
      padding: 5px 19px 5px 19px;
      margin-top: 3%;
      background-color: #2a7086;
      color: white;
    }
    Button:focus {
      background-color: #2a7086;
      color: white;
    }
    Button:hover {
      background-color: #2a7086;
      color: white;
    }
  }

  .preview-templates-cont {
    border: solid 1.7px #9bb1bb;
    border-radius: 10px 10px 0px 0px;
    overflow: auto;
    width: 84%;
    margin: 15px 44px 20px 1px;
    display: flex;
    height: 97%;
    overflow-x: hidden;
    overflow-y: auto;
    border-bottom: none;
    /* overflow: auto; */
    height: 82%;
  }

  .right {
    width: 270px !important;
  }

  .preview-templates-cont > div {
    height: 100%;
    display: flex;
  }

  .templates-heading-cont {
    // display: flex;

    // align-items: center;
    // justify-content: center;
    .heading-div > div {
      font-size: 23px;
      font-weight: 550;
      color: #2a7086;
      line-height: 1;
      margin: 2px;
    }
    .heading-div :last-child {
      color: #6c7a93;
      font-size: 10px;
      line-height: 2;
      margin: 2px;
    }
    hr {
      width: 98%;
      border-top: 2px solid #e1f1f5;
    }
  }

  .email-temp-container-middle {
    display: flex;
    height: 100%;

    .email-temp-categories-cont {
      padding: 16px;
      height: calc(100% - 65px);
      box-sizing: border-box;
      width: 25%;
      background-color: #ffffff;
      margin: 22px 19px 3px 3px;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      h3 {
        font-size: 18px;
        font-weight: 500;
      }
      ul {
        list-style: none;
        display: flex;
        size: 18px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
      }
      .email-templates-categories {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 9px;
        color: #2a7086;
        border-radius: 5px;
        margin: 5px;
      }
      .email-templates-categories:hover {
        cursor: pointer;
        background-color: #9bb1bb30;
      }
    }

    .Templates-container {
      display: flex;
      flex-wrap: wrap;
      overflow: auto;
      padding-top: 20px;
      /* padding: 10px; */
      height: calc(100% - 65px);
      box-sizing: border-box;
      width: 30%;
      margin: 8px 3px 3px;
      width: calc(100% - 193px);

      .default-cont-template {
        // width: 100%;
        display: flex;
        margin-right: 30px;
        justify-content: flex-end;
        .mention-default-template {
          background-color: #2a7086;
          color: white;
          border-radius: 5px 5px 0px 0px;
          padding: 2px 6px;
          font-size: 9px;
        }
      }

      .templateCard-body {
        overflow: auto;
        margin: 0px 26px;
        display: flex;
        justify-content: flex-end;
        /* align-items: center; */
        width: 168px;
        height: 212px;
        border-radius: 5px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
        border: solid #2a7086 0.6px;
        position: relative;

        .delete-template-div {
          display: flex;
          color: #2a7086;
          width: 100%;
          position: absolute;
          z-index: 2;
          margin: 5px 3px;
          justify-content: flex-end;
        }

        .delete-template {
          cursor: pointer;
          position: absolute;
          z-index: 2;
        }
        .email-temp-content {
          height: 100%;
          width: 100%;
          overflow-x: hidden;
          display: flex;
          // position: absolute;
          z-index: 1;
        }
      }

      .add-new-templateCard {
        overflow: auto;
        margin: 0px 26px;
        justify-content: center;
        display: flex;
        align-items: center;
        width: 168px;
        height: 212px;
        border-radius: 5px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
        border: solid #2a7086 0.6px;
        position: relative;

        .email-temp-content {
          height: 100%;
          width: 100%;
          overflow-x: hidden;
          display: flex;
          // position: absolute;
          z-index: 1;
        }
      }

      .edit-view {
        top: 44%;
        justify-content: flex-end;
        width: 100%;
        height: 56%;
        display: flex;
        position: absolute;
        /* bottom: 2rem; */
        /* right: 4rem; */
        z-index: 3;
        left: 0%;

        img {
          width: 20px;
        }

        .images {
          display: flex;
          align-items: flex-end;
          justify-content: space-evenly;
          width: 62px;
          margin-right: 0px;
          /* margin: 6px; */
          margin-bottom: 9px;
          display: none;
        }
      }
      .edit-view:hover {
        background: linear-gradient(to top, #2a7086 0%, #ffffff00 90%);
        cursor: pointer;
        .images {
          display: flex;
        }
      }

      .add-new-templateCard:hover {
        // background: linear-gradient(to top, #2A7086 0%, #FFFFFF 90%);
        cursor: pointer;
        z-index: 2;
      }

      .add-template-circle {
        height: 40px;
        width: 40px;
        /* border: solid; */
        border-radius: 100px;
        background-color: #ecf1f2;
        justify-content: center;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  .desicion {
    display: flex;
    align-items: center;
    // justify-content: space-evenly;
    // align-content: center;
  }

  .fields {
    input {
      // width: 346px !important;
      color: #2a7086 !important;
      border-radius: 5px !important;
      border: #9bb1bbbf solid 1px !important;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .decision {
    display: flex;
    justify-content: flex-end;
  }
  .temp-subject {
    color: #2a7086 !important;
    border-radius: 5px !important;
    border: #9bb1bbbf solid 1px !important;
  }

  .text-editor-options {
    display: flex;
    margin-left: 4px;
    margin-bottom: 4px;
    cursor: pointer;

    .text-editor-option.active {
      color: #2a7086;
      text-decoration: underline;
      font-weight: bold;
    }
  }

  .text-editor-email-templates {
    height: 46vh;

    #toolbar {
      display: flex;
      justify-content: space-evenly;
      display: flex;
    }
  }

  .insert-box {
    height: 28px;
    padding: 0px 22px !important;
    background-color: white;
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 0.6px solid #9bb1bb !important;
  }

  .semi-round-btn {
    border-radius: 4px;
  }

  .round-btn {
    border-radius: 14px;
  }
}

// create email
.create-Template {
  overflow: hidden;
  height: 100%;
}
.create-temp-modal-heading {
  display: flex;
  height: 8%;
  padding: 0px 26px;

  background-color: #2a7086;
  align-items: center;
  justify-content: space-between;
  /* margin: 0px 2px; */
  margin: 0px;
  color: white;

  button {
    border-radius: 23px;
    color: #2a7086;
  }

  h3 {
    color: white;
    font-weight: bolder;
    font-size: 18px;
  }
}

.create-template-modal-screen {
  .ant-modal-body {
    padding: 0px !important;
  }
  button {
    border-radius: 23px;
  }
}

.add-new-template-modal {
  .email-category-dropdown {
    width: 460px;
    // margin-left: 9px;
    background-color: white;
    border: 0.6px solid #9bb1bb !important;
    border-radius: 3px;
    padding: 6px 16px 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      cursor: pointer;
      width: 14px;
    }
  }

  .Assign-dropdown {
    background-color: white;
    width: 100%;
    position: absolute;
    border-radius: 4px;
    border: 0.6px solid #9bb1bb !important;
    z-index: 2;

    ul {
      list-style: none;
      padding: 10px;
      width: 100%;
      color: #2a7086;

      li {
        padding: 5px;
        width: 100%;
        cursor: pointer;
        margin: 5px;
        border-radius: 4px;
      }
      li:hover {
        background-color: #ced9de4d;
      }
    }
  }

  .Add-template-heading {
    background-color: #2a7086;
    display: flex;
    color: white;
    /* margin-top: 3px; */
    border-radius: 10px 10px 0px 0px;
    padding: 11px 15px 16px 18px;
    /* margin: 20px 20px; */
    justify-content: space-between;
    align-items: center;
    /* color: #2A7086; */
    h3 {
      color: white;
    }
    .create-btn {
      color: white;
      border-radius: 17px;
      padding: 14px, 20px, 14px, 20px !important;
      background-color: #2a7086;
    }
    .ant-form-item {
      margin-bottom: 0px !important;
    }
    .Template-name-box,
    .category-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .ant-form-item-explain-error {
        font-size: 9px !important;
        width: 46% !important;
        display: flex !important;
      }

      .ant-row {
        flex-direction: column !important;
      }
      .ant-form-item-row {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
    .Template-name-box:first-child {
      width: 496px !important;
      display: flex !important;
    }
    .template-name-cont {
      // width: 496px  !important;
      // display: flex !important;
      // width: 496px !important;
      flex-direction: column;
      .ant-row {
        flex-direction: column !important;
      }
    }
  }

  .anticon svg {
    color: white;
  }
  .ant-modal-content {
    border-radius: 10px !important;
  }
  .ant-modal-body {
    padding: 0px !important;
  }

  .addTemplateForm {
    .ant-switch-checked {
      background-color: #2a7086 !important;
    }
    margin: 10px;
    padding: 20px;

    .ant-form-item-label {
      justify-content: flex-start;
      display: flex;
    }

    .ant-row {
      display: flex;
      /* flex-flow: row wrap; */
      min-width: 0;
      flex-direction: column;
    }
  }
}

.confirmation-default-change-template {
  .ant-modal-content {
    background-color: white;
  }
  .ant-modal-body {
    padding-top: 24px !important;
  }
}

.Delete-template-confirmation {
  .ant-modal-content {
    border-radius: 5px;
  }
  .ant-modal-footer {
    background-color: white;
    display: flex;
    margin-top: 0px;
    justify-content: end;
    align-items: center;
  }

  .ant-modal-body {
    font-size: 16px;
    padding-top: 24px !important;
  }

  .ant-btn:hover,
  .ant-btn:focus {
    color: #2a7086;
    border-color: #2a7086;
    background: #fff;
  }

  .ant-btn-primary {
    border-color: #2a7086;
  }
}

.desicion {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // align-content: center;
  color: white;

  button {
    background-color: #2a7086;
    color: white;
    border-radius: 23px;
  }
}

//  #editor-2{
//     min-height: 90vh;
//     iframe{
//     //     min-width: none !important;
//     // min-height: none !important;

//     }
// }

// #editor-4{
//     min-height: 90vh;
//     iframe{
//     //     min-width: none !important;
//     // min-height: none !important;

//     }

// }

// iframe{
//     min-height: 90vh !important;
//     width: 98.7% !important;
// }
