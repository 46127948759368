.loader-overlay {
  position: fixed; /* Position fixed to cover the entire screen */
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  backdrop-filter: blur(1px);
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it stays on top of other elements */
}
