/* Global CSS */
//.ant-btn,.ant-btn-primar,.default-btn,.ant-btn-primary,.ant-btn-default,

.play-resume-icon,
.primary-button,
.more-btn,
.folder-add-btn,
.upload-button,
.exit-btn,
.import-csv-switch-selected,
.ant-btn-default,
.ant-btn-primary,
.create-asset-body .ant-btn,
.enroll-icon-wrapper button,
.bulk-skill-modal .add-button,
.ant-modal-footer .default-btn,
.leader-btn,
.install,
.permission-delete-modal .ant-modal-footer .delete-btn,
.permission_headerRight .Add_Permission,
.create_btn,
.create-product,
.Rs-GP-container .create_btn,
.save-btn {
  color: var(--secondary-text);
  --button-background: var(--secondary-bg);
  --button-border-color: var(--secondary-bg);
  --button-color: var(--secondary-text);
}

/* Button Styles */
.play-resume-icon,
.upload-folder-container .folder-add-btn,
.ant-modal-footer .default-btn,
.upload-button,
.quiz-player-next-btn,
.import-csv-switch-selected,
.default-btn,
.enroll-icon-wrapper button,
.bulk-skill-modal .add-button,
.leader-btn,
.Rs-GP-container .create_btn,
.permission-delete-modal .ant-modal-footer .delete-btn,
.edit-popup .cancel:hover,
.assign-permission-modal .ant-btn-background-ghost:hover,
.organisation-container .header-right .create_btn:hover,
.assets-container .bottom-right .ant-btn,
.create-modal-popup .ant-modal-content .ant-modal-footer .btn,
.install-btn,
.create-product,
.save-btn,
.install {
  background: var(--button-background) !important;
  border: 1px solid var(--button-border-color) !important;
}

.more-btn,
.organisation-container .header-right .create_btn,
.permission_headerRight .Add_Permission {
  border: 1px solid var(--button-border-color) !important;
}

.assets-container .ant-btn-link,
.ant-btn-background-ghost,
.camera-section .ant-btn:hover,
.edit-popup .cancel,
.ant-layout-header .ant-btn-default:hover,
.user-profile-main-container .ant-btn-default:hover,
.training-btn:hover,
.permission-table .ant-btn:hover,
.skills-table .ant-btn:hover,
.cancel-btn,
.create-Quiz-header .ant-btn-default,
.assign-permission-modal .ant-btn-background-ghost,
.training-section .ant-btn-default:hover .user-info-section .ant-btn:hover {
  background-color: inherit !important;
}

//user profile
.user-photo-container,
.user-profile-main-container .ant-btn-default,
.ant-layout-header .ant-btn-default,
.import-users-modal .cancel,
.edit-popup .ant-btn-default,
.permission-table .ant-btn:hover,
.skills-table .ant-btn:hover,
.cancel-btn,
.installed-btn,
.create-Quiz-header .ant-btn-default,
.ant-dropdown-menu .ant-btn:hover {
  border: inherit !important;
}

//global upload btn assets
.upload-globally-btn {
  width: 150px;
}

// hover font color
.feedback-container .ant-btn:hover span,
.import-users-modal .ant-btn-primary,
.organisation-container .header-right .ant-btn-default:hover,
.anticon-delete,
.sub-header-lp .ant-btn span:hover,
.assign-permission-modal .ant-btn-background-ghost:hover,
.organisation-container .header-right .create_btn:hover,
.settings-section-quiz .setting-footer .draft-btn:hover,
.settings-section-quiz .ant-btn-default:hover,
.create-product,
.cancel-btn:hover {
  color: var(--secondary-text);
}
.Rs-GP-container .cancel-btn:hover {
  color: #6c7a93 !important;
}
//courses btn
.learningPath-container {
  color: var(--button-color) !important;
  background-color: var(--button-background) !important;
}

// // assets screen
// .assets-container .ant-btn {
//     background-color: var(--button-background) !important;
// }

// .assets-container .ant-btn-link:hover {
//     background-color: var(--button-background) !important;
// }

// .assets-container .ant-btn:hover {
//     border: 1px solid var(--button-border-color) !important;
// }
